import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordValidators } from './ConfirmPassword';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder, private auth: AuthService, private routes: ActivatedRoute, private router: Router) { }

  token: string;
  email: string;

  ngOnInit(): void {
    this.token = this.routes.snapshot.paramMap.get("token");
    this.email = this.routes.snapshot.paramMap.get("mail");
  }

  update(){
    this.auth.updatePassword(this.token, this.email, this.loginForm.value.password).subscribe((data: any) => {
      Swal.fire(
        'Contraseña cambiada con éxito!',
        'Ahora intente iniciar sesión nuevamente',
        'success'
      );
      this.router.navigateByUrl('/ingresar');
    }, (error: any) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error interno del servidor. Intente nuevamente mas tarde',
          showConfirmButton: false,
          timer: 1500
        });
    });
  }

  loginForm = this.fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  },
  {
     // check whether our password and confirm password match
     validator: PasswordValidators.passwordMatch
  });

}
