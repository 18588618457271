import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminCreateModel, AdminModel, AdminUpdateModel } from 'src/app/models/admin';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crud-admin',
  templateUrl: './crud-admin.component.html',
  styleUrls: ['./crud-admin.component.scss']
})
export class CrudAdminComponent implements OnInit {

  filename: string;
  file: FileList;
  private containFile: boolean = false;
  archivo: any;
  aux: string[];
  isEdit: boolean = false;
  newAdmin: AdminCreateModel = new AdminCreateModel();
  updateAdmin: AdminUpdateModel = new AdminUpdateModel();
  editAdmin: AdminModel;
  private adminID: number;
  private IMG_URL = environment.IMG_URL;
  constructor(private route: Router, private core: CoreService, private auth: AuthService, private routes: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.filename = 'Nombre del archivo.JPG';
    let id = this.routes.snapshot.paramMap.get("id");
    if (id != null) {
      let valoresAceptados = /^[0-9]+$/;
      if (id.match(valoresAceptados)) {
        this.isEdit = true;
        this.showAdmin(parseInt(id));
        this.adminID = parseInt(id);
      } else {
        console.log('No Aceptado');
        this.route.navigateByUrl('/404');
      }

    }
  }


  showAdmin(id: number) {
    this.core.showAdmin(id).subscribe((data: any) => {
      this.editAdmin = data.data;
      console.log(data);
      this.description = this.editAdmin.description;
      this.linkedin = this.editAdmin.linkedin;
      this.position = this.editAdmin.position;
      this.email = this.editAdmin.email;
      this.firstName = this.editAdmin.firstName;
      this.lastName = this.editAdmin.lastName;
      this.adminForm.get('isTrainer').setValue(this.editAdmin.isTrainer);
      let result = this.editAdmin.phone.split('-');
      let result2 = result[0].split(' ');
      let result3 = result2[0].split('+');
      this.codPais = result3[1];
      this.carac = result2[1];
      this.phone = result[1];
      this.photo = this.editAdmin.photo;
      console.log(this.editAdmin);
    }, (error: any) => {
      console.log(error.status)
      if (error.error.status == 401) {
        this.auth.refreshToken();
        this.showAdmin(id);
      } else if (error.error.status == 500) {
        this.route.navigateByUrl('/500')
      }
      console.error(error);
    });
  }


  addOrEditAdmin() {
    if (!this.isEdit) {
      let urlImg: string;
      console.log(this.newAdmin);
      this.newAdmin.description = this.adminForm.value.description;
      this.newAdmin.linkedin = this.adminForm.value.linkedin;
      this.newAdmin.position = this.adminForm.value.position;
      this.newAdmin.isTrainer = this.adminForm.value.isTrainer;
      this.newAdmin.email = this.adminForm.value.email;
      this.newAdmin.firstName = this.adminForm.value.firstName;
      this.newAdmin.lastName = this.adminForm.value.lastName;
      this.newAdmin.phone = `+${this.adminForm.value.codPais} ${this.adminForm.value.carac}-${this.adminForm.value.phone}`;
      console.log(this.newAdmin);
      this.core.existUser(this.newAdmin.email).subscribe((data: any) => {
        if (!data.exist) {
          if (this.containFile) {
            this.core.uploudFile(this.file[0] ? this.file[0] : null).subscribe((data: any) => {
              urlImg = `${this.IMG_URL}${data.data}`;
              console.log(urlImg);
              this.newAdmin.photo = urlImg;
              this.core.addAdmin(this.newAdmin).subscribe((data1: any) => {
                console.log(data1);
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Se ha agradado un nuevo capacitador con éxito',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.goToAdmins();
              }, (error: any) => {
                if (error.status == 401) {
                  this.auth.refreshToken();
                } else if (error.status == 500) {
                  this.route.navigateByUrl('/500')
                }
                console.error(error);
              });
            }, (error: any) => {
              if (error.error.code == 4) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Archivo no admitido',
                  showConfirmButton: false,
                  timer: 1500
                });
              }
              if (error.status == 401 && error.error.code != 4) {
                this.auth.refreshToken();
              } else if (error.status == 500) {
                this.route.navigateByUrl('/500')
              }
              console.error(error);
            });
          } else {
            this.core.addAdmin(this.newAdmin).subscribe((data1: any) => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Se ha agradado un nuevo capacitador con éxito',
                showConfirmButton: false,
                timer: 1500
              })
              this.goToAdmins();
            }, (error: any) => {
              if (error.status == 401) {
                this.auth.refreshToken();
              } else if (error.status == 500) {
                this.route.navigateByUrl('/500')
              } else if (error.status == 400) {
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Usuario no encontrado',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            });
          }

        } else {
          console.error('El capacitador ya existe!')
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ya existe un capacitador en ese correo electrónico',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }, (error: any) => {
        if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.route.navigateByUrl('/500')
        }
        console.error(error);
      });
    } else {
      let urlImg: string;
      this.updateAdmin.description = this.adminForm.value.description;
      this.updateAdmin.linkedin = this.adminForm.value.linkedin;
      this.updateAdmin.position = this.adminForm.value.position;
      this.updateAdmin.isTrainer = this.adminForm.value.isTrainer;
      this.updateAdmin.email = this.adminForm.value.email;
      this.updateAdmin.firstName = this.adminForm.value.firstName;
      this.updateAdmin.lastName = this.adminForm.value.lastName;
      this.updateAdmin.phone = `+${this.adminForm.value.codPais} ${this.adminForm.value.carac}-${this.adminForm.value.phone}`;
      console.log(this.updateAdmin);
      if (this.containFile) {
        this.core.uploudFile(this.file[0] ? this.file[0] : null).subscribe((data: any) => {
          urlImg = `${this.IMG_URL}${data.data}`;
          console.log(urlImg);
          this.updateAdmin.photo = urlImg;
          this.core.updateAdmin(this.adminID, this.updateAdmin).subscribe((data1: any) => {
            console.log(data1);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se ha actualizado el capacitador con éxito',
              showConfirmButton: false,
              timer: 1500
            })
            this.goToAdmins();
          }, (error: any) => {
            if (error.error.code == 6) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Ya existe un capacitador en ese correo electrónico',
                showConfirmButton: false,
                timer: 1500
              });
            } else if (error.status == 401 && error.error.code != 6) {
              this.auth.refreshToken();
            } else if (error.status == 500) {
              this.route.navigateByUrl('/500')
            }
            console.error(error);
          });
        }, (error: any) => {
          if (error.error.code == 4) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Archivo no admitido',
              showConfirmButton: false,
              timer: 1500
            });
          }
          if (error.status == 401 && error.error.code != 4) {
            this.auth.refreshToken();
          } else if (error.status == 500) {
            this.route.navigateByUrl('/500')
          }
          console.error(error);
        });
      } else {
        if (this.photo != null) {
          this.updateAdmin.photo = this.photo;
        }
        this.core.updateAdmin(this.adminID, this.updateAdmin).subscribe((data1: any) => {
          console.log(data1);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se ha actualizado el capacitador con éxito',
            showConfirmButton: false,
            timer: 1500
          });
          this.goToAdmins();
        }, (error: any) => {
          if (error.error.code == 6) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Ya existe un capacitador en ese correo electrónico',
              showConfirmButton: false,
              timer: 1500
            });
          } else if (error.status == 401 && error.error.code != 6) {
            this.auth.refreshToken();
          } else if (error.status == 500) {
            this.route.navigateByUrl('/500')
          } else if (error.status == 400) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No se encuentra el usuario',
              showConfirmButton: false,
              timer: 1500
            })
          }
          console.error(error);
        });
      }
    }

  }


  goToAdmins() {
    this.route.navigateByUrl('/administracion')
  }

  print(e){
    console.log(this.adminForm);
  }

  filechange(files: FileList) {
    this.file = files;
    this.filename = files[0].name;
    this.containFile = true;
  }


  description: string;
  linkedin: string;
  position: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  carac: string;
  photo: string;
  codPais: string;


  numPattern = /^\d+$/;
  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/;
  // Formulario reactivo
  adminForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    position: ['', [Validators.maxLength(35)]],
    description: ['', Validators.maxLength(300)],
    isTrainer: [false],
    photo: [''],
    email: ['', [Validators.required, Validators.email]],
    linkedin: ['', [Validators.maxLength(25)]],
    codPais: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern(this.numPattern)]],
    carac: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(5), Validators.pattern(this.numPattern)]],
    phone: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(8), Validators.pattern(this.numPattern)]],
  });

}
