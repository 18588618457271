import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dirmod-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  years: Year[] = [];
  year: number[] = [];
  month: number;
  anio: number;
  selectedMonth: boolean[] =
    [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ];

  selectedMYear: boolean[] =
    [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    ];

  @Input() selectedDate: Date;
  @Output() endDate = new EventEmitter<Date>()

  constructor(private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.RawYears();
    this.SetDateSelected();
    this.endDate.emit(this.selectedDate);
  }

  SetDateSelected() {
    switch (parseInt(this.datepipe.transform(this.selectedDate, 'MM'))) {
      case 1:
        this.selectedMonth[0] = true;
        break;
      case 2:
        this.selectedMonth[1] = true;
        break;
      case 3:
        this.selectedMonth[2] = true;
        break;
      case 4:
        this.selectedMonth[3] = true;
        break;
      case 5:
        this.selectedMonth[4] = true;
        break;
      case 6:
        this.selectedMonth[5] = true;
        break;
      case 7:
        this.selectedMonth[6] = true;
        break;
      case 8:
        this.selectedMonth[7] = true;
        break;
      case 9:
        this.selectedMonth[8] = true;
        break;
      case 10:
        this.selectedMonth[9] = true;
        break;
      case 11:
        this.selectedMonth[10] = true;
        break;
      case 12:
        this.selectedMonth[11] = true;
        break;
    }
  }

  RawYears() {
    let x: Year;
    x = {year: new Date().getFullYear() - 5, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() - 4, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() - 3, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() - 2, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() - 1, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear(), selected: true };
    this.years.push(x);
    x = {year: new Date().getFullYear() + 1, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() + 2, selected: false };
    this.years.push(x);
    x = {year: new Date().getFullYear() + 3, selected: false };
    this.years.push(x);
  }

  isMonthChanged: boolean = false;
  isYearChanged: boolean = false;
  yearSelected: number;
  monthSelected: number;
  ChangeSelections(event, isMonth:boolean = true) {
    if (isMonth) {
      this.isMonthChanged = true;
      this.monthSelected = parseInt(event.target.value) - 1;
      if (this.isYearChanged) {
        this.endDate.emit(new Date(this.yearSelected, parseInt(event.target.value) - 1));
      } else {
        this.endDate.emit(new Date(this.selectedDate.getFullYear(), parseInt(event.target.value) - 1));
      }

    } else {
      this.isYearChanged = true
      this.yearSelected = parseInt(event.target.value)
      if (this.isMonthChanged) {
        this.endDate.emit(new Date(parseInt(event.target.value), this.monthSelected));
      } else {
        this.endDate.emit(new Date(parseInt(event.target.value), parseInt(this.datepipe.transform(this.selectedDate, 'MM')) - 1));
      }
    }
  }
}

interface Year {
  year: number;
  selected: boolean;
}
