<div class="custom-picker">
  <div class="container-fluid">
    <div class="row justify-content-center flx">
      <div class="col-auto" style="padding-right: 0;">
        <select (change)="ChangeSelections($event)" name="month" class="dirmod-select" id="month">
          <option [selected]="selectedMonth[0]" value="01">Enero</option>
          <option [selected]="selectedMonth[1]" value="02">Febrero</option>
          <option [selected]="selectedMonth[2]" value="03">Marzo</option>
          <option [selected]="selectedMonth[3]" value="04">Abril</option>
          <option [selected]="selectedMonth[4]" value="05">Mayo</option>
          <option [selected]="selectedMonth[5]" value="06">Junio</option>
          <option [selected]="selectedMonth[6]" value="07">Julio</option>
          <option [selected]="selectedMonth[7]" value="08">Agosto</option>
          <option [selected]="selectedMonth[8]" value="09">Septiembre</option>
          <option [selected]="selectedMonth[9]" value="10">Octubre</option>
          <option [selected]="selectedMonth[10]" value="11">Noviembre</option>
          <option [selected]="selectedMonth[11]" value="12">Diciembre</option>
        </select>
      </div>
      <div class="col-auto">
        <div class="line"></div>
      </div>
      <div class="col-auto" style="padding-left: 0;">
        <select (change)="ChangeSelections($event, false)" name="years" class="dirmod-select" id="years">
          <option [value]="item.year" [selected]="item.selected" *ngFor="let item of years">{{item.year}}</option>
        </select>
      </div>
    </div>
  </div>
</div>

