<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row justify-content-mobile">
    <h2>Nuevo Capacitador</h2>
  </div>
  <div class="row mt-4 justify-content-mobile">
    <h3>Completá el formulario</h3>
  </div>
  <div class="row">
    <div class="line"></div>
  </div>
  <div class="row mt-4 mb-3 justify-content-center">
    <form (ngSubmit)="addOrEditTrainer()" [formGroup]="trainerForm" style="width: 80%;">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="name">Nombre*</label>
          <input type="text" name="name" [(ngModel)]="firstName" required minlength="2" maxlength="25" formControlName="firstName" class="form-control" id="name"
            placeholder="ej: Mirian">
            <div *ngIf="trainerForm.controls.firstName.invalid && (trainerForm.controls.firstName.dirty || trainerForm.controls.firstName.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.firstName.errors?.required">
                El nombre es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.firstName.errors?.minlength">
                El nombre debe contener mas de 2 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.firstName.errors?.maxlength">
                El nombre debe contener menos de 25 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.firstName.errors?.pattern">
                El nombre tiene un formato no admitido.
              </div>
            </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="lastname">Apellido*</label>
          <input type="text" name="lastname" [(ngModel)]="lastName" required minlength="2" maxlength="25" formControlName="lastName" class="form-control" id="lastname"
            placeholder="ej: Gonzales">
            <div *ngIf="trainerForm.controls.lastName.invalid && (trainerForm.controls.lastName.dirty || trainerForm.controls.lastName.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.lastName.errors?.required">
                El apellido es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.lastName.errors?.minlength">
                El apellido debe contener mas de 2 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.lastName.errors?.maxlength">
                El apellido debe contener menos de 25 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.lastName.errors?.pattern">
                El apellido tiene un formato no admitido.
              </div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="cargo">Ocupación</label>
          <input type="text" name="position" [(ngModel)]="position" maxlength="35" formControlName="position" class="form-control" id="cargo"
            placeholder="ej: Gerente">
            <div *ngIf="trainerForm.controls.position.invalid && (trainerForm.controls.position.dirty || trainerForm.controls.position.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.position.errors?.maxlength">
                La ocupación debe contener menos de 35 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.position.errors?.pattern">
                La ocupación tiene un formato no admitido.
              </div>
            </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="desc">Descripción</label>
          <textarea class="form-control form-control-1" [(ngModel)]="description" maxlength="300" formControlName="description" name="description" id="desc"
            placeholder="ej: Personal interno de Barbieri"></textarea>
            <div *ngIf="trainerForm.controls.description.invalid && (trainerForm.controls.description.dirty || trainerForm.controls.description.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.description.errors?.maxlength">
                La descripción debe contener menos de 300 caracteres.
              </div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="validatedCustomFile">Foto de Perfil</label>
          <input #fileInput type="file" class="custom-file-input" (change)="filechange(fileInput.files)"
            accept="image/x-png,image/jpeg,image/jpg" id="validatedCustomFile" lang="es">
          <label class="custom-file-label" for="validatedCustomFile" data-browse="Seleccionar">{{filename}}</label>
          <small id="emailHelp" class="form-text text-muted mt-4 ml-4">Formato .JPG, .PNG. Peso máximo de 2MB. </small>
        </div>
        <div class="form-group col-lg-6">
          <label for="email">Correo electrónico*</label>
          <input type="email" class="form-control" required [(ngModel)]="email" formControlName="email" name="email" id="email"
            placeholder="ej: email@email.com">
            <div *ngIf="trainerForm.controls.email.invalid && (trainerForm.controls.email.dirty || trainerForm.controls.email.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.email.errors?.required">
                El email es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.email.errors?.email">
                El email tiene un formato no admitido.
              </div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="profile">Perfil de LinkedIn</label>
          <input type="text" class="form-control" maxlength="25" [(ngModel)]="linkedin" formControlName="linkedin" name="linkedin" id="profile"
            placeholder="ej: mirianbg1982">
            <div *ngIf="trainerForm.controls.linkedin.invalid && (trainerForm.controls.linkedin.dirty || trainerForm.controls.linkedin.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.linkedin.errors?.maxlength">
                El perfil de LinkedIn debe contener menos de 25 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.linkedin.errors?.pattern">
                El nombre tiene un formato no admitido.
              </div>
            </div>
        </div>
        <div class="form-group col-lg-6">
          <div class="form-row">
            <div class="form-group col-3">
              <label for="carc">Celular*</label>
              <input type="tel" [(ngModel)]="codPais" minlength="1" maxlength="3" class="form-control" required formControlName="codPais" [pattern]="numPattern" name="codPais" id="codPais"
                placeholder="Cod. País">
            </div>
            <div class="form-group col-3">
              <input type="tel" [(ngModel)]="carac" minlength="2" maxlength="5" class="form-control" required formControlName="carac" [pattern]="numPattern" name="carac" id="carc" style="margin-top: 32px;"
                placeholder="Cod. Área">
            </div>
            <div class="form-group col-6">
              <input type="tel" (ngModelChange)="print($event)" [(ngModel)]="phone" minlength="5" maxlength="8" class="form-control" [pattern]="numPattern" required formControlName="phone" name="phone" style="margin-top: 32px;"
                id="phone" placeholder="N° de Celular">
            </div>

          </div>
          <div *ngIf="(trainerForm.controls.carac.invalid || trainerForm.controls.phone.invalid || trainerForm.controls.codPais.invalid) && ((trainerForm.controls.carac.dirty || trainerForm.controls.codPais.dirty || trainerForm.controls.phone.dirty) || (trainerForm.controls.carac.touched || trainerForm.controls.phone.touched || trainerForm.controls.codPais.touched))">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.phone.errors?.required || trainerForm.controls.carac.errors?.required || trainerForm.controls.codPais.errors?.required">
              El celular es requerido.
            </div>
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.phone.errors?.minlength || trainerForm.controls.carac.errors?.minlength  || trainerForm.controls.codPais.errors?.minlength">
              El celular tiene un formato no admitido.
            </div>
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainerForm.controls.phone.errors?.pattern || trainerForm.controls.carac.errors?.pattern  || trainerForm.controls.codPais.errors?.pattern">
              El celular tiene un formato no admitido (contiene letras).
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mt-2 justify-content-center text-center">
        <div class="form-group col-lg-6">
          <button class="btn btn-back" (click)="goToTrainers()">Atrás</button>
        </div>
        <div class="form-group col-lg-6">
          <button class="btn btn-save" [disabled]="!trainerForm.valid" type="submit">Guardar</button>
        </div>
      </div>
    </form>
  </div>
</div>
